import React from 'react';
import SourceOfIncomeTotalSum from "./SourceOfIncomeTotalSum";
import {
    setEmployerPensionSpouse,
    setEmployerPensionYou,
} from "../../../../store/actions/SourceOfIncomeActions";
import { connect } from "react-redux";

const SourceOfIncomeEmployerPensionSum = ( props ) => {

    return (
        <SourceOfIncomeTotalSum
            totalYou = {props.employerPensionTotalYou}
            totalSpouse = {props.employerPensionTotalSpouse}
            setYou = {props.setEmployerPensionOasAmountYou}
            setSpouse = {props.setEmployerPensionAmountSpouse}
            label = 'Employer Pension'
            max = {100000}
        />
    )
};

function mapStateToProps(state) {
    return {
        employerPensionTotalYou: state.sourceOfIncome.you.employerPension,
        employerPensionTotalSpouse: state.sourceOfIncome.spouse.employerPension,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setEmployerPensionOasAmountYou: (val) => dispatch(setEmployerPensionYou(val)),
        setEmployerPensionAmountSpouse: (val) => dispatch(setEmployerPensionSpouse(val)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SourceOfIncomeEmployerPensionSum);