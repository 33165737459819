import React from 'react';
import { setCppSpouse, setCppYou } from "../../../../store/actions/SourceOfIncomeActions";
import { connect } from "react-redux";
import SourceOfIncomeTotalSum from "./SourceOfIncomeTotalSum";

const SourceOfIncomeCppSum = ( props ) => {

    return (
       <SourceOfIncomeTotalSum
            totalYou = {props.cppTotalYou}
            totalSpouse = {props.cppTotalSpouse}
            setYou = {props.setCppAmountYou}
            setSpouse = {props.setCppAmountSpouse}
            label = 'CPP'
            max={3000}
       />
    )
};

function mapStateToProps(state) {
    return {
        cppTotalYou: state.sourceOfIncome.you.cpp,
        cppTotalSpouse: state.sourceOfIncome.spouse.cpp,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setCppAmountYou: (val) => dispatch(setCppYou(val)),
        setCppAmountSpouse: (val) => dispatch(setCppSpouse(val)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SourceOfIncomeCppSum);