import React from 'react';
import SourceOfIncomeTotalSum from "./SourceOfIncomeTotalSum";
import {
     setOtherSpouse, setOtherYou,
} from "../../../../store/actions/SourceOfIncomeActions";
import { connect } from "react-redux";

const SourceOfIncomeOtherSum = ( props ) => {

    return (
        <SourceOfIncomeTotalSum
            totalYou = {props.otherTotalYou}
            totalSpouse = {props.otherTotalSpouse}
            setYou = {props.setOtherAmountYou}
            setSpouse = {props.setOtherAmountSpouse}
            label = 'Other'
            max = {99999}
        />
    )
};

function mapStateToProps(state) {
    return {
        otherTotalYou: state.sourceOfIncome.you.other,
        otherTotalSpouse: state.sourceOfIncome.spouse.other,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setOtherAmountYou: (val) => dispatch(setOtherYou(val)),
        setOtherAmountSpouse: (val) => dispatch(setOtherSpouse(val)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SourceOfIncomeOtherSum);