import React from 'react';
import SourceOfIncomeTotalSum from "./SourceOfIncomeTotalSum";
import {
    setOasPensionSpouse,
    setOasPensionYou,
} from "../../../../store/actions/SourceOfIncomeActions";
import { connect } from "react-redux";

const SourceOfIncomeOasSum = ( props ) => {

    return (
        <SourceOfIncomeTotalSum
            totalYou = {props.oasTotalYou}
            totalSpouse = {props.oasTotalSpouse}
            setYou = {props.setOasAmountYou}
            setSpouse = {props.setOasAmountSpouse}
            label = 'OAS'
            max = {800}
        />
    )
};

function mapStateToProps(state) {
    return {
        oasTotalYou: state.sourceOfIncome.you.oasPension,
        oasTotalSpouse: state.sourceOfIncome.spouse.oasPension,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setOasAmountYou: (val) => dispatch(setOasPensionYou(val)),
        setOasAmountSpouse: (val) => dispatch(setOasPensionSpouse(val)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SourceOfIncomeOasSum);