import React from 'react';
import { setQppSpouse, setQppYou } from "../../../../store/actions/SourceOfIncomeActions";
import { connect } from "react-redux";
import SourceOfIncomeTotalSum from "./SourceOfIncomeTotalSum";

const SourceOfIncomeQppSum = ( props ) => {

    return (
        <SourceOfIncomeTotalSum
            totalYou = {props.qppTotalYou}
            totalSpouse = {props.qppTotalSpouse}
            setYou = {props.setQppAmountYou}
            setSpouse = {props.setQppAmountSpouse}
            label = 'QPP'
            max = {3000}
        />
    )
};

function mapStateToProps(state) {
    return {
        qppTotalYou: state.sourceOfIncome.you.qpp,
        qppTotalSpouse: state.sourceOfIncome.spouse.qpp,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setQppAmountYou: (val) => dispatch(setQppYou(val)),
        setQppAmountSpouse: (val) => dispatch(setQppSpouse(val)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SourceOfIncomeQppSum);