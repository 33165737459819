import React, { useEffect } from 'react';
import Layout from "../../../hoc/Layout/Layout";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { fourthStepCompleted } from "../../../store/actions/SourceOfIncomeActions";
import classes from "../../../components/AboutYouSummary/AboutYouSummaryComp/AboutYouSummaryComp.module.scss";
import { Grid } from "react-flexbox-grid";
import AboutYouSumHeader from "../../../components/AboutYouSummary/AboutYouSumHeader";
import AboutYouSumSave from "../../../components/AboutYouSummary/AboutYouSumSave";
import SourceOfIncomeQppSum from "../../../components/ContentRow/Step4/SourceOfIncomeSum/SourceOfIncomeQppSum";
import SourceOfIncomeCppSum from "../../../components/ContentRow/Step4/SourceOfIncomeSum/SourceOfIncomeCppSum";
import SourceOfIncomeOasSum from "../../../components/ContentRow/Step4/SourceOfIncomeSum/SourceOfIncomeOasSum";
import SourceOfIncomeEmployerPensionSum
    from "../../../components/ContentRow/Step4/SourceOfIncomeSum/SourceOfIncomeEmployerPensionSum";
import SourceOfIncomeOtherSum from "../../../components/ContentRow/Step4/SourceOfIncomeSum/SourceOfIncomeOtherSum";
import Navigation from "../../../components/Navigation/Navigation";
import { retirementCalcLinks } from "../../../Routes/RoutesConst";

const SourceOfIncomeSummary = ( props ) => {
    const titles = {
        layoutTitle: 'INCOME SOURCES SUMMARY',
    };

    useEffect(() => {
        props.fourthStepCompleted(true)
    }, [props]);

    return (
        <Layout
            title={titles.layoutTitle}
            progressStep4={100}
            active={'step4'}
        >
            <Helmet>
                <title>{titles.layoutTitle}</title>
            </Helmet>

            <form className={classes.AboutYouSummary}>
                <Grid>
                    <AboutYouSumHeader/>

                    {
                        (props.sourceOfIncomeYou.cpp.exist || props.sourceOfIncomeSpouse.cpp.exist) &&
                        <SourceOfIncomeCppSum/>
                    }

                    {
                        (props.sourceOfIncomeYou.qpp.exist || props.sourceOfIncomeSpouse.qpp.exist) &&
                        <SourceOfIncomeQppSum/>
                    }

                    {
                        (props.sourceOfIncomeYou.oasPension.exist || props.sourceOfIncomeSpouse.oasPension.exist) &&
                        <SourceOfIncomeOasSum/>
                    }

                    {
                        (props.sourceOfIncomeYou.employerPension.exist || props.sourceOfIncomeSpouse.employerPension.exist) &&
                        <SourceOfIncomeEmployerPensionSum/>
                    }

                    {
                        (props.sourceOfIncomeYou.other.exist || props.sourceOfIncomeSpouse.other.exist) &&
                        <SourceOfIncomeOtherSum/>
                    }

                    <AboutYouSumSave/>
                </Grid>
            </form>

            <Navigation
                isCompleted={true}
                next={retirementCalcLinks.retirementResults}
            />
        </Layout>
    )
};

function mapStateToProps(state) {
    return {
        sourceOfIncomeYou: state.sourceOfIncome.you,
        sourceOfIncomeSpouse: state.sourceOfIncome.spouse,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        fourthStepCompleted: (val) => dispatch(fourthStepCompleted(val))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SourceOfIncomeSummary);